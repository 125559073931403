import "./App.css";
import Hamburger from "hamburger-react";
import { useState } from "react";
import ReactPlayer from "react-player";

function App() {
  const [hamburgerOpen, setHamburgerOpen] = useState(false);
  const [amenitiesOpen, setAmenitiesOpen] = useState(false);

  const [secondaryContentOpen, setSecondaryContentOpen] = useState(false);

  const [secondaryContentL1, setSecondaryContentL1] = useState(false);
  const [secondaryContentL2, setSecondaryContentL2] = useState(false);
  const [secondaryContentL3, setSecondaryContentL3] = useState(false);
  const [fullScreenOverlay, setFullScreenOverlay] = useState(false);

  return (
    <div className="app">
      <div className="bg-video">
        <ReactPlayer
          url="/video/casita-village-hero-bg-v1.mp4"
          muted={true}
          playing={true}
          width={"100%"}
          height={"auto"}
          loop={true}
        />
      </div>

      <header className="header">
        <div className="hamburger-container">
          <Hamburger
            toggled={hamburgerOpen}
            toggle={setHamburgerOpen}
            playing={true}
            loop={true}
            color={"#FFFFFF"}
            size={50}
          />
        </div>
      </header>

      <div className={`nav-menu ${hamburgerOpen ? "" : "collapsed"}`}>
        <ul>
          <li
            onClick={() => {
              setHamburgerOpen(false);
              setAmenitiesOpen(false);
              setSecondaryContentOpen(true);
              setSecondaryContentL1(true);
              setSecondaryContentL2(false);
              setSecondaryContentL3(false);
            }}
          >
            Styles
          </li>
          <li
            onClick={() => {
              setHamburgerOpen(false);
              setAmenitiesOpen(false);
              setSecondaryContentOpen(true);
              setSecondaryContentL1(false);
              setSecondaryContentL2(true);
              setSecondaryContentL3(false);
            }}
          >
            Floorplans
          </li>
          <li
            onClick={() => {
              setHamburgerOpen(false);
              setAmenitiesOpen(false);
              setSecondaryContentOpen(true);
              setSecondaryContentL1(false);
              setSecondaryContentL2(false);
              setSecondaryContentL3(true);
            }}
          >
            Cul-De-Sac Living
          </li>
          <li
            onClick={() => {
              setHamburgerOpen(false);
              setSecondaryContentOpen(false);
              setAmenitiesOpen(true);
            }}
          >
            Amenities &amp; Services
          </li>
        </ul>
      </div>

      <div className="main-content">
        <div className="primary-logo-container">
          <img className="primary-logo" src="/images/casita-village-logo.png" />
        </div>

        <div
          className={`amenity-menu ${
            amenitiesOpen ? "" : "amenity-menu-collapsed"
          }`}
        >
          <div className="amenity-row">
            <div className="amenity-item">
              <div>
                <img src="/images/icons/home-services.png" />
              </div>
              <div>Home Services</div>
            </div>
            <div className="amenity-item">
              <div>
                <img src="/images/icons/delivery.png" />
              </div>
              <div>Delivery</div>
            </div>
            <div className="amenity-item">
              <div>
                <img src="/images/icons/automotive.png" />
              </div>
              <div>Automotive</div>
            </div>
            <div className="amenity-item">
              <div>
                <img src="/images/icons/transportation.png" />
              </div>
              <div>Transportation</div>
            </div>
            <div className="amenity-item">
              <div>
                <img src="/images/icons/health-wellness.png" />
              </div>
              <div>Health & Wellness</div>
            </div>
          </div>
          <div className="amenity-row">
            <div className="amenity-item">
              <div>
                <img src="/images/icons/entertainment.png" />
              </div>
              <div>Entertainment</div>
            </div>
            <div className="amenity-item">
              <div>
                <img src="/images/icons/security.png" />
              </div>
              <div>Security</div>
            </div>
            <div className="amenity-item">
              <div>
                <img src="/images/icons/recreation.png" />
              </div>
              <div>Recreation</div>
            </div>
            <div className="amenity-item">
              <div>
                <img src="/images/icons/pets.png" />
              </div>
              <div>Pets</div>
            </div>
            <div className="amenity-item">
              <div>
                <img src="/images/icons/map.png" />
              </div>
              <div>Map</div>
            </div>
          </div>
        </div>
      </div>

      <div
        className={`secondary-content-container ${
          secondaryContentOpen ? "" : "secondary-content-container-hidden"
        }`}
      >
        <div
          className={`secondary-content-l1 ${
            secondaryContentL1 ? "" : "secondary-content-ln-hidden"
          }`}
        >
          <div className="secondary-logo-container">
            <img
              className="secondary-logo"
              src="/images/casita-village-logo.png"
            />
          </div>
          <div>
            <div className="fifth-width">
              <img className="full-width" src="/images/styles/adobe.jpg" />

              <div className="velum-title">Adobe</div>
            </div>
            <div className="fifth-width">
              <img className="full-width" src="/images/styles/castle.jpg" />

              <div className="velum-title">Castle</div>
            </div>
            <div className="fifth-width">
              <img className="full-width" src="/images/styles/barn.jpg" />

              <div className="velum-title">Barn</div>
            </div>
            <div className="fifth-width">
              <img className="full-width" src="/images/styles/victorian.jpg" />

              <div className="velum-title">Victorian</div>
            </div>
            <div className="fifth-width">
              <img className="full-width" src="/images/styles/modern.jpg" />

              <div className="velum-title">Modern</div>
            </div>
          </div>
          <div>
            <div className="fifth-width">
              <img className="full-width" src="/images/styles/colonial.jpg" />

              <div className="velum-title">Colonial</div>
            </div>
            <div className="fifth-width">
              <img className="full-width" src="/images/styles/tahiti.jpg" />

              <div className="velum-title">Tahiti</div>
            </div>
            <div className="fifth-width">
              <img className="full-width" src="/images/styles/bahama.jpg" />

              <div className="velum-title">Bahamas</div>
            </div>
            <div className="fifth-width">
              <img className="full-width" src="/images/styles/log.jpg" />

              <div className="velum-title">Log Cabin</div>
            </div>
            <div className="fifth-width">
              <img className="full-width" src="/images/styles/town.jpg" />

              <div className="velum-title">Town Home</div>
            </div>
          </div>
        </div>

        <div
          className={`secondary-content-l2 ${
            secondaryContentL2 ? "" : "secondary-content-ln-hidden"
          }`}
        >
          <div>
            <div className="half-width">
              <img className="full-width" src="/images/layouts/casita.jpg" />

              <div className="velum-title">Casita</div>
            </div>
            <div className="half-width">
              <img className="full-width" src="/images/layouts/1-bedroom.jpg" />

              <div className="velum-title">One Bedroom</div>
            </div>
            <div className="half-width">
              <img className="full-width" src="/images/layouts/2-bedroom.jpg" />

              <div className="velum-title">Two Bedroom</div>
            </div>
            <div className="half-width">
              <img className="full-width" src="/images/layouts/3-bedroom.jpg" />

              <div className="velum-title">Three Bedroom</div>
            </div>
          </div>
        </div>

        <div
          className={`secondary-content-l3 ${
            secondaryContentL3 ? "" : "secondary-content-ln-hidden"
          }`}
        >
          <div className="overlay-logo-container">
            <img
              className="secondary-logo"
              src="/images/casita-village-logo.png"
            />
          </div>

          <div
            className="floating-button"
            onClick={() => {
              setFullScreenOverlay(true);
            }}
          >
            Learn More
          </div>

          <img className="full-screen" src="/images/casita-village-v2.jpg" />
        </div>
      </div>
      <div
        className="full-screen-overlay"
        className={`full-screen-overlay ${
          fullScreenOverlay ? "" : "full-screen-overlay-hidden"
        }`}
      >
        <div
          className="close-full-screen-overlay"
          onClick={() => {
            setFullScreenOverlay(false);
          }}
        >
          X
        </div>
        <img
          className="community-bullets"
          src="/images/hex-community-bullets.jpg"
        />
      </div>
    </div>
  );
}

export default App;
